<template>
  <v-container fluid>
    <v-tabs v-model="tab" background-color="primary" dark>
      <v-tab :key="1"> Price guide </v-tab>
      <v-tab :key="2"> Org data </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item :key="1">
        <v-card>
          <v-card-text>
            <div
              style="border: 1px solid #cecece; background-color: #f5f5f5"
              class="my-2"
            >
              <v-row>
                <v-col cols="4" class="pa-1" v-if="currentDefinition">
                  <h5>Details</h5>
                  <b>{{ currentDefinition.title }}</b
                  ><br />
                  <b>Effective From:</b>
                  <span class="caption">{{
                    currentDefinition.effectiveFromDate.substring(0, 10)
                  }}</span
                  ><br />
                  <b>Effective To:</b>
                  <span class="caption">{{
                    currentDefinition.effectiveToDate.substring(0, 10)
                  }}</span
                  ><br />
                </v-col>
                <v-col cols="2"
                  ><v-btn small type="button" @click="getCurrentDefinitions()"
                    >Load or Change</v-btn
                  ></v-col
                >
                <v-col cols="6" v-if="changingDefinition">
                  <v-select
                    v-model="selectedDefinition"
                    :items="currentDefinitions"
                    label="currentDefinitions"
                    return-object
                  >
                    <template v-slot:item="{ item }">
                      {{ item.title }}
                    </template>
                    <template v-slot:selection="{ item }">
                      {{ item.title }}
                    </template>
                  </v-select>
                  <v-btn type="button" @click="setSelectedLineItemDefinition()"
                    >set selected Price guide</v-btn
                  >
                </v-col>
              </v-row>
            </div>

            <v-data-table
              v-if="currentDefinition"
              :items="currentDefinition.lineItems"
              :dense="true"
              :items-per-page-options="[50]"
              :items-per-page="50"
              :headers="[
                { text: 'supportCategoryNumber', value: 'supportCategoryNumber' },
                { text: 'supportCategory', value: 'supportCategory' },
                { text: 'registrationGroupNumber', value: 'registrationGroupNumber' },

                { text: 'registrationGroup', value: 'registrationGroup' },
                { text: 'supportItemNumber', value: 'supportItemNumber' },
                { text: 'supportItem', value: 'supportItem' },
                { text: 'supportItemDescription', value: 'supportItemDescription' },
              ]"
              class="elevation-1 smallTextTable"
            >
              <template slot="item.supportCategoryNumber" slot-scope="props">
                {{ props.item.supportCategoryNumber }}<br />
              </template>
              <template slot="item.supportCategory" slot-scope="props">
                {{ props.item.supportCategory }}
              </template>
              <template slot="item.registrationGroupNumber" slot-scope="props">
                {{ props.item.registrationGroupNumber }}
              </template>

              <template slot="item.registrationGroup" slot-scope="props">
                {{ props.item.registrationGroup }}
              </template>
              <template slot="item.supportItemNumber" slot-scope="props">
                {{ props.item.supportItemNumber }}
              </template>
              <template slot="item.supportItem" slot-scope="props">
                {{ props.item.supportItem }}
              </template>
              <template slot="item.supportItemDescription" slot-scope="props">
                {{ props.item.supportItemDescription }}
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="4">
                <v-select
                  v-model="selectedOrganisation"
                  :items="organisations"
                  label="organisations"
                  return-object
                  item-text="name"
                  filled
                  @change="onOrganisationSelected"
                >
                </v-select>

                <v-btn type="button" @click="updateAll()">Update All</v-btn>

                <div class="my-4">or</div>

                <v-select
                  v-model="selectedParticipants"
                  :items="participants"
                  label="Select"
                  multiple
                  chips
                  return-object
                  filled
                  item-text="name"
                  persistent-hint
                >
                </v-select>
                <v-btn type="button" @click="updateSelected()">Update Selected</v-btn>

                <v-checkbox
                  v-model="forceUpdate"
                  key="forceUpdate"
                  name="forceUpdate"
                  label="Force Update"
                  hint="will update even when no update is required"
                ></v-checkbox>
              </v-col>

              <v-col cols="8">
                <v-textarea
                  v-model="log"
                  filled
                  label="Update log"
                  rows="60"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import Vue from "vue";

// You need a specific loader for CSS files
import "vue-datetime/dist/vue-datetime.css";
//const csv = require("fast-csv");
import * as lineItemService from "../../services/lineItemSevice";
import * as organisationService from "../../services/organisationService";
import * as migrationService from "../../services/migrationService";
import * as caseService from "../../services/caseService";

import VeeValidate from "vee-validate";
Vue.use(VeeValidate);

export default {
  $_veeValidate: {
    validator: "new",
  },
  name: "lineItems",
  data: () => ({
    vm: {},
    payload: "",
    tab: null,
    registrationGroup: null,
    supportCategory: null,
    selectedOrganisation: null,
    selectedDefinition: null,
    currentDefinition: null,
    changingDefinition: false,
    forceUpdate: false,
    log: "",
    organisations: [],
    currentDefinitions: [],
    participants: [],
    selectedParticipants: [],
    items: [],
    itemsFiltered: [],
    supportCategories: [],
    lineItems: [],
    csvUploadPath: "/system/line-items/",
  }),

  props: {},
  mounted() {
    this.init();
  },
  computed: {},
  methods: {
    async init() {
      const me = this;
      organisationService.listEnabled().then((results) => {
        results.docs.map((doc) => {
          me.organisations.push(doc.data());
        });
      });

      this.currentDefinition = migrationService.getSelectedLineItemDefinition();
      lineItemService.listAllLineItems(this.currentDefinition.id).then((results) =>{
      results.docs.map((doc) => {
      let lineItem = doc.data();
        lineItem.id = doc.id;
        this.lineItems.push(lineItem);
      });  
     });
      this.currentDefinition.lineItems = this.lineItems;
      console.log(this.currentDefinition);
    },

    getCurrentDefinitions() {
      const me = this;
      this.changingDefinition = true;
      lineItemService.listCurrent().then((results) => {
        results.docs.map((doc) => {
          const data = doc.data();
          data.effectiveFromDate = me.$utils.toDt(data.effectiveFromDate).toISOString();
          data.effectiveToDate = me.$utils.toDt(data.effectiveToDate).toISOString();
          me.currentDefinitions.push(data);
          
        });
      });
      console.log(me.currentDefinitions);


    },

    setSelectedLineItemDefinition() {
      this.changingDefinition = false;
     // let lineItems = JSON.parse(window.localStorage.getItem("currentLineItems"));
      this.currentDefinition= this.currentDefinitions[0];
     lineItemService.listAllLineItems(this.currentDefinition.id).then((results) =>{
      results.docs.map((doc) => {
      let lineItem = doc.data();
        lineItem.id = doc.id;
        this.lineItems.push(lineItem)
      });  
     });
      this.currentDefinition.lineItems = this.lineItems;
      console.log(this.currentDefinition.id);
      migrationService.setSelectedLineItemDefinition(this.currentDefinition);
    },

    updateAll() {
      this.selectedParticipants = this.participants;
      this.updateLineItems();
    },

    updateSelected() {
      this.updateLineItems();
    },

    updateEffectiveFromDate: function (datetime) {
      this.vm.effectiveFromDate = datetime;
    },
    updateEffectiveToDate: function (datetime) {
      this.vm.effectiveToDate = datetime;
    },

    importLineItems() {},
    onOrganisationSelected() {
      const me = this;
      me.participants = [];
      caseService.list(me.selectedOrganisation.id).then((results) => {
        results.docs.map((doc) => {
          const data = doc.data();
          if (data.participantDetails) {
            me.participants.push({
              data: data,
              doc: doc,
              name: data.participantDetails
                ? data.participantDetails.firstname +
                  " " +
                  data.participantDetails.lastname
                : "",
            });
          }
        });
      });
    },

    updateNotes(participant, budgetItemData, goalData, goalRef, budgetItem) {
      const me = this;
      const notesRef = goalRef
        .collection("progressNotes")
        .where("budgetItem.id", "==", budgetItem.id);

      return notesRef
        .get()
        .then((notes) => {
          notes.docs.map((note) => {
            const noteData = note.data();

            noteData.id = note.id;
            if (noteData.budgetItem !== undefined) {
              noteData.budgetItem = budgetItem;
            }
            if (noteData.budgetItem !== undefined && noteData.lineItem !== undefined) {
              const found = budgetItem.lineItems.find((i) => {
                return i.supportItemNumber === noteData.lineItem.supportItemNumber;
              });
              if (
                found !== undefined &&
                found !== null &&
                found.id !== noteData.lineItem.id
              ) {
                noteData.lineItem.isOld = true;
              }
            }

            me.logProgress(
              participant.data,
              budgetItemData,
              goalData,
              noteData,
              "Updating note"
            );
            return note.ref.update(noteData);
          });
        })
        .catch((error) => {
          me.logProgress(participant.data, budgetItemData, goalData, null, error);
        });
    },

    updatebudgetItemGoals(participant, budgetItemDoc, budgetItemData) {
      const me = this;

      return budgetItemDoc.ref
        .update(budgetItemData)
        .then((result) => {
          me.logProgress(
            participant.data,
            budgetItemData,
            null,
            null,
            "budget item updated"
          );
          console.log(result);

          const goalsRef = participant.doc.ref.collection("goals");
          return goalsRef
            .get()
            .then((goals) => {
              goals.docs.map((goal) => {
                const goalData = goal.data();
                if (
                  goalData !== undefined &&
                  goalData.budgetItem !== undefined &&
                  goalData.budgetItem !== null &&
                  goalData.budgetItem.id === budgetItemData.id
                ) {
                  //update the default budget item at the goal level
                  goalData.budgetItem = budgetItemData;
                  if (goalData.lineItem !== undefined && goalData.lineItem !== null) {
                    //update the default line item at the goal level
                    const found = budgetItemData.lineItems.find((i) => {
                      return i.supportItemNumber === goalData.lineItem.supportItemNumber;
                    });
                    if (
                      found !== undefined &&
                      found !== null &&
                      found.id !== goalData.lineItem.id
                    ) {
                      goalData.lineItem = found;
                    }
                  }

                  me.logProgress(
                    participant.data,
                    budgetItemData,
                    goalData,
                    null,
                    "Updating goal"
                  );
                  goal.ref.update(goalData);
                  me.updateNotes(
                    participant,
                    budgetItemData,
                    goalData,
                    goal.ref,
                    budgetItemData
                  );
                  return null;
                }
              });
            })
            .catch((error) => {
              me.logProgress(participant.data, budgetItemData, null, null, error);
            });
        })
        .catch((error) => {
          me.logProgress(participant.data, budgetItemData, null, null, error);
        });
    },

    logMsg(message) {
      this.log = message + "\n" + this.log;
    },
    logProgress(participant, budgetItem, goal, note, message) {
      const participantString =
        (participant.participantDetails
          ? participant.participantDetails.firstname +
            " " +
            participant.participantDetails.lastname
          : "") + ` (${participant.id})`;

      let newMessage = `Organisation: (${this.selectedOrganisation.id}) -> ${participantString} -> `;

      if (budgetItem !== null) {
        newMessage += `BudgetItem: ${budgetItem.title} (${budgetItem.id}) -> `;
      }

      if (goal !== null) {
        newMessage += `Goal: ${goal.title} (${goal.id}) -> `;
      }

      if (note !== null) {
        newMessage += `ProgressNote: (${note.id}) -> `;
      }

      newMessage += message;

      this.logMsg(newMessage);
    },

    async updateLineItems() {
      const me = this;

      this.selectedParticipants.forEach((participant) => {
        if (
          participant.data.planDetails !== undefined &&
          participant.data.planDetails.planEnd !== undefined
        ) {
          const endDate = new Date(participant.data.planDetails.planEnd);
          const now = new Date();
          if (endDate > now) {
            me.logProgress(participant.data, null, null, null, "Begin");
            const budgetItemsRef = participant.doc.ref.collection("budgetItems");

            return budgetItemsRef
              .get()
              .then((budgetItems) => {
                budgetItems.docs.map(async (budgetItem) => {
                  const budgetItemData = budgetItem.data();
                  me.logProgress(
                    participant.data,
                    budgetItemData,
                    null,
                    null,
                    "Building revised set of line items and checking if needs update"
                  );
                  const revisedItems = [];
                  let needsUpdate = false;

                  //get a list of items that will be stored in the revised budget items.
                  for (let i = 0; i <= budgetItemData.lineItems.length - 1; i++) {
                    const lineItem = budgetItemData.lineItems[i];
                    let foundItem = this.currentDefinition.lineItems.find((x) => {
                      return x.supportItemNumber == lineItem.supportItemNumber;
                    });

                    if (!me.$utils.isEmpty(foundItem)) {
                      if (foundItem.id !== lineItem.id) {
                        foundItem.nDISManaged = lineItem.nDISManaged;
                        revisedItems.push(foundItem);
                        needsUpdate = true;
                      } else {
                        revisedItems.push(lineItem);
                      }
                    }
                  }

                  if (needsUpdate === true || this.forceUpdate) {
                    me.logProgress(
                      participant.data,
                      budgetItemData,
                      null,
                      null,
                      "Flaged as Needing Update"
                    );
                    budgetItemData.lineItems = revisedItems;
                    me.updatebudgetItemGoals(participant, budgetItem, budgetItemData);
                  } else {
                    me.logProgress(
                      participant.data,
                      budgetItemData,
                      null,
                      null,
                      "Flaged as no update required"
                    );
                  }
                });
              })
              .catch((error) => {
                me.logProgress(participant.data, null, null, null, error);
              });
          }
        }
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
